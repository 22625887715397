.header-section {
  position: relative;
  z-index: 100;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: relative;
}

.logo img {
  max-height: 50px;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.mainmenu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mainmenu ul li {
  margin-right: 20px;
}

.mainmenu ul li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;
}

.primary-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
  transition: 0.3s;
}

.nav-links .dropdown {
  position: relative;
}

.nav-links .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 5px;
}

.nav-links .dropdown-menu.open {
  display: block;
}

.nav-links .dropdown-menu li {
  padding: 10px 20px;
  margin: 0;
}

.nav-links .dropdown-menu li:hover {
  background-color: rgba(240, 118, 118, 0.398);
}

.nav-links .dropdown-menu li a {
  color: black;
  text-decoration: none;
  font-weight: 600;
}

.nav-links.open {
  display: block;
}

.nav-links .dropdown-menu .nominate-now {
  background-color: rgba(243, 11, 11, 0.398);
}

@media screen and (max-width: 868px) {
  .mainmenu ul {
    position: fixed; 
    top: 120px;
    right: 0;
    background-color: #fff;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    display: none; 
    z-index: 200; 
  }

  .mainmenu ul.open {
    display: block; 
    transform: translateX(0);
  }

  .mainmenu ul li {
    margin: 10px 0;
  }

  .primary-btn {
    margin-top: 10px;
  }

  .hamburger {
    display: flex;
  }
}

@media screen and (min-width: 1400px) {
  .navbar {
    justify-content: center; 
  }

  .navbar > .logo {
    position: absolute;
    left: 20px; 
  }

  .navbar > .logo {
    position: absolute;
    left: 20px; 
  }

  .navbar > .right{
    position: absolute;
    right: 20px;
  }

  .navbar > .nav-menu {
    margin: 0 auto;
  }
}
