:root {
  --primary-color-1: #f44949; /* Bright red */
  --light-grey: #a0a1b5;
  --verylightpink: hsl(0, 100%, 98%); /* Nearly white */
  --lightred: hsl(0, 80%, 80%);
  --red: hsl(0, 70%, 70%);
  --darkred: hsl(0, 60%, 50%);
  --gradient1: hsl(0, 100%, 80%); /* Light red */
  --gradient2: hsl(0, 100%, 50%); /* Darker red */
  --lineargradient: linear-gradient(90deg, var(--gradient1), var(--gradient2));
}
