.ct-address {
  margin-bottom: 15px;
}

.section-title {
  text-align: left;
  margin-bottom: 40px;
}

.address span {
  font-size: 16px;
  color: #6a6b7c;
}

.address p {
  font-size: 18px;
  color: #171822;
  line-height: 30px;
  margin-top: 8px;
}

.text ul {
  margin-bottom: 22px;
}

.text ul li {
  font-size: 18px;
  color: #171822;
  line-height: 36px;
  list-style-type: none;
  display: inline-block;
  margin-right: 42px;
}

.text ul li:last-child {
  margin-right: 0;
}

.text ul li span {
  font-size: 16px;
  color: #6a6b7c;
  display: block;
}

.links span {
  font-size: 16px;
  color: #6a6b7c;
}

.x-padding {
  padding: 0;
}

.contact-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
  align-items: center;
}

.links p {
  font-size: 18px;
  color: #171822;
  line-height: 30px;
  margin-top: 8px;
}

.map {
  height: 400px;
  -webkit-box-shadow: 0px 12px 30px rgba(11, 12, 48, 0.15);
  box-shadow: 0px 12px 30px rgba(11, 12, 48, 0.15);
}

.map iframe {
  width: 100%;
}
