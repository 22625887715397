.bg-gradient,
.bd-text .bd-tag-share .s-share a:hover,
.bh-text .play-btn,
.nav-tabs .nav-item .nav-link.active,
.ni-form button,
.li-tag,
.tr-tag,
.nav-tabs .nav-item .nav-link.active,
.site-btn {
  background-image: -o-linear-gradient(
      330deg,
      #f4a6a6 0%,
      #f44949 50%,
      #d32f2f 100%
    ),
    -o-linear-gradient(330deg, #f4a6a6 0%, #f44949 50%, #d32f2f 100%);
  background-image: linear-gradient(
      120deg,
      #f4a6a6 0%,
      #f44949 50%,
      #d32f2f 100%
    ),
    linear-gradient(120deg, #f4a6a6 0%, #f44949 50%, #d32f2f 100%);
}

.primary-btn {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 40px;
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#f44949),
      to(#d32f2f)
    ),
    -webkit-gradient(linear, left top, right top, from(#f44949), to(#d32f2f));
  background-image: -o-linear-gradient(left, #f44949 0%, #d32f2f 100%),
    -o-linear-gradient(left, #f44949 0%, #d32f2f 100%);
  background-image: linear-gradient(to right, #f44949 0%, #d32f2f 100%),
    linear-gradient(to right, #f44949 0%, #d32f2f 100%);
}
