.sponsorship-section{
  padding: 0px 20px;
}

.header {
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.value-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  gap: 20px;
  padding: 20px 40px;
  overflow-x: hidden;
  overflow-y: auto;
}

.grid-item {
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.event-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.grid-item img {
  max-width: 100%;
  height: auto;
  padding: 5px;
  border-radius: 2%;
}

.grid-item ol li {
  font-size: 22px;
  text-align: left;
  padding: 10px;
}

.grid-item ol li::marker {
  font-weight: bold;
}

.left-align {
  padding-left: 100px;
}

.right-align {
  padding-right: 100px;
}

@media screen and (max-width: 800px) {
  .value-grid {
    grid-template-columns: 1fr;
    padding: 10px 20px;
  }

  .grid-item {
    padding: 15px;
  }

  .grid-item img {
    max-width: 100%;
  }

  .grid-item:nth-child(3) {
    order: 4;
  }

  .left-align,
  .right-align {
    padding: 0;
  }
}

/* Sponsorship Options Section */
.sponsorship-options {
  padding: 40px 20px;
  text-align: center;
}

.options-grid {
  padding-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.option-item {
  padding: 20px;
  border-radius: 8px;
  background-color: rgba(152, 68, 68, 0.209);
  border: 1px rgba(255, 0, 0, 0.108) solid; /* Keep this border and remove the other one */
  box-shadow: rgba(116, 59, 59, 0.71) 0px 4px 8px;
  transition: transform 0.3s ease-in-out; /* Smooth transition for the hover effect */
}

.option-grid::nth-child(3){
  display: flex;
  justify-content: center;
}

.option-item:hover {
  transform: scale(1.05); /* Correct way to scale the item on hover */
}


.price {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 21px;
}

.sponsorship-instructions {
  padding: 40px 20px;
  text-align: center;
  border: 1px rgba(255, 0, 0, 0.108) solid; 
  box-shadow: rgba(116, 59, 59, 0.71) 0px 4px 8px;
  background-color: rgba(255, 195, 195, 0.065);
}

.instructions-list {
  list-style: decimal;
  padding-left: 20px;
  font-size: 18px;
  text-align: left;
  margin: 0 auto;
  max-width: 800px; /* Adjust the width as needed */
}

.instructions-list li {
  margin-bottom: 10px;
}

@media screen and (min-width: 600px) {
  .options-grid {
    grid-template-columns: 1fr 1fr;
  }

  .grid-item ol li {
    font-size: 18px;
  }
}

@media screen and (min-width: 900px) {
  .options-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .grid-item ol li {
    font-size: 18px;
  }
}

@media screen and (max-width: 400px) {
  .instructions-list  {
    font-size: 16px;
  }
}
