.counter-section {
  padding: 40px 0;
}

.counter-text {
  font-size: 16px;
  color: #ffffff;
}

.counter-text h3 {
  color: #ffffff;
  font-weight: 700;
}

.cd-timer {
  text-align: right;
}
.cd-item {
  display: inline-block;
  text-align: center;
  margin-left: 35px;
  height: 120px;
  width: 120px;
  padding-top: 32px;
  position: relative;
}

.cd-item:first-child {
  margin-left: 0;
}

.cd-item span {
  font-size: 60px;
  color: #ffffff;
  font-weight: 600;
  line-height: 30px;
}

.cd-item p {
  font-size: 18px;
  color: #ffffff;
  margin-bottom: 0;
  line-height: 30px;
}

@media only screen and (max-width: 1200px) {
  .cd-item span {
    font-size: 40px;
  }

  .cd-timer{
    display: flex;
  }

  .counter-section{
    text-align: center;
  }
}
