.awardees-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.awardees-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--verylightpink);
}

.awardees-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.awardees-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.awardees-title {
  margin: 0 15px 15px 15px;
  color: #666;
}
.award {
  margin: 0 15px 15px 15px;
  font-weight: bold;
  font-size: 1.3rem;
}

.awardees-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px;
}

.awardees-name {
  font-size: 1.5em;
  margin-right: 20px;
  font-weight: 600;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.hidden {
  display: none;
}

.social-icon {
  color: #333;
  text-decoration: none;
}

.social-icon i {
  font-size: 1.2em;
  color: black;
}

.image-section {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.image-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top;
}

@media only screen and (max-width: 767px) {
  .awardees-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}



