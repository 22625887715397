.home-about-section {
  position: relative;
}

h4{
  padding: 10px 0px;
}

.text-align{
  text-align: justify !important;
}

.welcome-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: center;
  justify-content: space-evenly;
  padding: 20px;
}

.welcome-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
}

.book-title{
  font-style: italic;
  padding-top: 10px;
}

.text-container {
  width: 50%;
  padding-left: 20px;
}

.preview{
  list-style: none;
  display: none;
}

.preview-wide{
  list-style: none;
}

.video-container {
  width: 50%;
  width: 700px;
  height: 500px;
}

.hero-video {
  width: 100%;
  height: 100%;
}

.image-container {
  float: right;
  height: auto; /* Maintain aspect ratio */
}

.image-container img {
  height: auto;
  width: 250px;
  
}

.ptop{
  padding-top: 20px;
}

.text-container h2 {
  padding-bottom: 1rem;
}

/* ------------------------------------------------- */

.banner-center {
  padding: 150px 20px;
}

.center-div{
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.center-mag{
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  padding: 5px 100px;    
}

.mag-title{
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 40px;
  text-align: center;
  text-decoration: underline;
}

.feedback{
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.hero-text span {
  font-size: 16px;
  color: #fefefe;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
}

.hero-section .hero-text h2 {
  font-size: 60px;
  color: #ffffff;
  line-height: 4rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.hero-bg {
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.hero-bg::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.152);
}

.slogan{
  font-weight: bold;
  font-size: 28px;
  text-align: center;
}

.home-about-section {
  padding-bottom: 90px;
}

.header-padding{
  padding: 25px 0px;
}

.about-pic {
  min-width: 100%;
  border-radius: 2px;
}

.flyer {
  max-width: 100%;      
  object-fit: cover;     
  width: 100%;           
  max-width: 555px; 
}

.ha-text {
  padding-top: 4rem;
}

.ha-text h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 32px;
}

.ha-text p {
  margin-bottom: 2.5rem;
}

.ha-text ul {
  margin-bottom: 2.5rem;
}

.ha-text ul li {
  list-style: none;
  font-size: 16px;
  line-height: 30px;
}

.ha-text ul li span {
  color: var(--primary-colour-1);
}

.schedule-image {
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(255, 0, 0, 0.5); 
  width: 560px; 
  height: auto;           
  margin: 0 auto;  
}

.schedule-container{
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 
}


@media only screen and (max-width: 1300px) {
  .welcome-container {
    flex-direction: column;
    padding-bottom: 0;
  }

  .center-mag{
    padding: 5px 30px;    
  }

  .mag-title{
    font-size: 20px;
  }

  .text-container {
    width: 100%;
    padding: 20px 5px 0px 5px;
  }

  .text-container h2 {
    text-align: center;
  }

  .video-container{
    width: 100%;
  }

  .home-about-section {
    padding: 0;
  }

  .center {
    padding: 100px 20px;
    text-align: center;
  }

  .ha-text h2{
    text-align: center;
  }

  .hero-section .hero-text h2 {
    font-size: 40px;
  }

  .image-container {
    text-align: center;
  }
}