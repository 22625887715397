.pricing-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 20px;
}

.pricing-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 150, 150, 0.2);
  z-index: 1;
}

.pricing-body {
  position: relative;
  text-align: center;
  z-index: 2;
}

.pricing-container h1 {
  font-weight: 700;
  margin-bottom: 3rem;
}

.pricing-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing-card {
  width: 15rem;
  height: auto;
  padding: 1.5rem;
  border-radius: 10px;
  background: #fcfeff;
  box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
  margin: 0 0.5rem;
  transition: all 0.6s ease;
  border: 1px black solid;
}

.pricing-card:hover {
  background: var(--lineargradient);
  color: #fcfeff !important;
  box-shadow: 0 35px 20px #777;
  margin: 0 -1rem;
  z-index: 1;
}

.pricing-card:hover p,
.pricing-card:hover .toggle-monthly,
.pricing-card:hover .toggle-monthly span {
  color: #fcfeff !important;
  transition: all 0.6s ease;
}

p .price-detail{
  margin: 0;
  font-size: 12px;
}

.fa fa-check-circle{
  color: #333;
}

.pricing-header {
  margin: 1rem 1rem 0 1rem !important;
}

.pricing-card p:nth-child(1) {
  font-size: 18px;
  margin: 1rem;
  color: var(--grayishblue);
}

.price-container {
  height: 6rem;
  overflow: hidden;
}

.price {
  font-size: 3rem !important;
  font-weight: 800;
  margin-bottom: 1rem !important;
  color: var(--darkgrayishblue) !important;
  display: flex;
  justify-content: center;
  transform: translateY(0px);
  transition: all 0.6s ease;
}

.pricing-card ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pricing-card {
  margin: 0rem 1.5rem;
}

.pricing-card li {
  border-top: 1px solid var(--darkpink);
  border-spacing: 1rem auto;
  display: flex;
  align-items: center;
}

.pricing-card button {
  margin: 1rem;
  padding: 0.7rem 2rem;
  color: #fcfeff;
  background: var(--lineargradient);
  border-radius: 7px;
  font-size: 11px;
  border: none;
  font-weight: 100;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.6s ease;
}

.pricing-card button:focus {
  outline: none;
}

.pricing-card:hover button {
  background: #fcfeff;
  color: var(--gradient2);
  outline: none;
}

.pricing-card button:after {
  background: var(--grayishblue);
  content: "";
  height: 155px;
  left: -75px;
  opacity: 0.1;
  position: absolute;
  top: -50px;
  transform: rotate(35deg);
  transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  width: 50px;
}

.pricing-card button:hover {
  box-shadow: 2px 4px 8px 0px rgba(46, 61, 73, 0.2);
}

.pricing-card button:hover:after {
  left: 120%;
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
}

.special-tables-section {
  background-color: #f8f9fa;
  padding: 50px 20px;
  margin-bottom: 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.special-tables-section .section-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.table-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}


.table-item {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(106, 59, 59, 0.71);
  transition: transform 0.3s ease;
  cursor: pointer;
  align-items: center;
}

.table-item:hover {
  transform: scale(1.05);
}

.table-item a{
  text-decoration: none;
}

.table-image {
  margin-right: 20px;
  overflow: hidden;
  flex-basis: 40%;
}

.table-image img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  border-radius: 10px;
}

.table-info {
  text-align: left;
  background-color: #f8f9fa;
  flex-basis: 60%;
}

.table-number {
  font-weight: bold;
  margin-right: 10px;
  color: #ff0000;
  font-size: 1.5rem;
}

.table-name {
  font-size: 1.2rem;
  color: #333;
}

.table-name:hover {
  text-decoration: rgb(255, 150, 150) underline;
}

.disclaimer{
  padding-top: 20px;
  font-style: italic;
  font-size: 14px;
}

.special-tables-message{
  padding: 10px;
}


@media (max-width: 1100px) {
  .pricing-container {
    height: auto;
  }

  .pricing-body {
    transform: translate(0, 0);
  }

  .pricing-card-container {
    flex-wrap: wrap;
  }

  .pricing-card {
    margin: 0 auto 2rem auto;
  }

  .pricing-card:hover {
    margin: 0 auto 2rem auto;
  }

  .pricing-container img:nth-child(2) {
    width: 50%;
  }
}

@media (max-width: 883px) {
  .table-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 515px) {
  .table-list {
    grid-template-columns: repeat(1, 1fr);
  }
}
