.title{
  padding-top: 1rem;
}

.guest-speaker-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 20px;
  align-items: baseline;
}

.guest-speaker-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%; 
}

.guest-speaker-image-container {
  position: relative;
  display: inline-block;
  padding: 6px;
  border-radius: 50%;
  background: linear-gradient(45deg, #d52727 0%, #e8a7a7 100%);  transition: transform 0.6s cubic-bezier(0.76, 0, 0.24, 1), box-shadow 0.8s cubic-bezier(0.76, 0, 0.24, 1);
}

.guest-speaker-image-container:hover {
  transform: scale(1.1) rotate(1deg);
  transform-origin: center center;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25), 0 0 48px rgba(0, 0, 0, 0.35);
}

.guest-speaker-image {
  border-radius: 50%; 
  width: 190px;
  height: 190px;
  object-fit: cover; 
}

.guest-speaker-name {
  font-size: 18px;
  font-weight: bold;
  margin-top: 12px;
  font-family: math;
}

.organization {
  font-size: 14px;
  color: gray;
}

@media only screen and (max-width: 1200px) {
  
  .subheader{
    padding: 0 10px 0 10px;
  }
  
  .guest-speaker-grid {
    grid-template-columns: repeat(5, 1fr);
  }

  .guest-speaker-image {
    width: 150px;
    height: 150px;
  }
}

@media only screen and (max-width: 700px) {
  .guest-speaker-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
