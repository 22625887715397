.footer-section {
  padding-top: 50px;
  padding-bottom: 60px;
}

.footer-section .header{
  text-decoration: underline;
  font-size: 36px;
  padding-bottom: 1rem;
}

.partner-logo {
  padding-bottom: 50px;
  border-bottom: 1px solid #2f3039;
  display: flex;
  justify-content: center; 
  align-items: center;
  gap: 20px; 
}

.pl-table {
  display: inline-block;
  max-width: 220px; 
}

.footer-text {
  text-align: center;
  padding-top: 60px;
}

.ft-logo {
  margin-bottom: 20px;
}

.footer-text ul li {
  list-style: none;
  display: inline-block;
  margin-right: 52px;
}

.footer-text ul li:last-child {
  margin-right: 0;
}

.footer-text ul li a {
  font-size: 18px;
  color: var(--light-grey);
  display: inline-block;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding-bottom: 20px;
}

.footer-text ul li a:hover {
  color: var(--primary-colour-1);
}

.ft-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  color: rgb(148, 43, 43);
  border: 1px solid #45464e;
  border-radius: 50%;
  margin-right: 15px;
}
.ft-social a:hover {
  color: var(--primary-colour-1);
}
.ft-social a:last-child {
  margin-right: 0;
}

