/* App.css or styles.css */
.newsletter-signup {
    text-align: center;
    margin-top: 50px;
    background: url('../img/maple-leaf.jpg');
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5); 
    background-blend-mode: overlay;
    background-size: cover;
    background-position: center;
  }

  .newsletter-signup-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .newsletter-signup h2, .newsletter-signup h5, .statusMessage{
    color: #ffffff;
  }

  .newsletter-signup h5{
    font-style: italic;
  }
  
  .newsletter-signup form {
    display: inline-block;
    margin-top: 10px;
    justify-content: center;
  }
  
  .newsletter-signup input {
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .newsletter-signup button {
    padding: 8px 16px;
    background-color:  rgb(220, 64, 64);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px;
  }
  
  .newsletter-signup button:hover {
    background-color: rgb(148, 43, 43);
  }

  .statusMessage{
    font-size: 20px;
  }

  .newsletter-img{
    width: 385px;
    height: 253px;
    height: auto;
    display: block; 
  }

  @media screen and (max-width: 484px) {
    .newsletter-signup form{
        display: contents;
    }
    .newsletter-signup form input{
        margin: 10px;
    }
    .newsletter-signup{
      flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .newsletter-signup{
    flex-direction: column;
  }
}

  